import axios from 'axios';
import lodash from 'lodash';
import { httpRoutesURL } from '@/config';

const controller = {};
controller.analytics = {};
controller.posts = {};
controller.twitter = {};
controller.users = {};
controller.aiTweets = {};
controller.links = {};
controller.autoDM = {};
controller.youtube = {};

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    throw error;
  }
);

controller.twitter.getLastNTweets = (firebaseUser, currentUserId, numberOfTweetsToFetch) => {
  return firebaseUser.getIdToken().then((token) => {
    const url = `${httpRoutesURL}/twitter/getLastTweets/${currentUserId}?numberOfTweetsToFetch=${numberOfTweetsToFetch}`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios.get(url, { headers });
  });
};

controller.twitter.generateGptTweets = (body, firebaseUser) => {
  return firebaseUser.getIdToken().then((token) => {
    const url = `${httpRoutesURL}/ai/generateTweetsGpt4`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios.post(url, body, { headers });
  });
}

controller.twitter.getFollowingsByAuthorIds = (authorIds, firebaseUser, currentUserId) => {
  return firebaseUser.getIdToken().then((token) => {
    const url = `${httpRoutesURL}/twitter/getTwitterFollowingsByAuthorIds/${currentUserId}`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const body = { authorIds };
    return axios.post(url, body, { headers });
  });
}

controller.links.shortenUrls = (firebaseUser, currentUserId, data) => {
  return firebaseUser.getIdToken().then((token) => {
    const headers = { Authorization: `Bearer ${token}` };
    const body = {
      currentUserId,
      ...data,
    };
    return axios.post(`${httpRoutesURL}/links/shortenLinks`, body, { headers });
  });
};

controller.links.getShortenedUrlsBySlugs = (firebaseUser, currentUserId, slugs) => {
  return firebaseUser.getIdToken().then((token) => {
    const headers = { Authorization: `Bearer ${token}` };
    const body = {
      currentUserId,
      slugs,
    };
    return axios.post(`${httpRoutesURL}/links/getLinksBySlugs`, body, { headers }); });
}

controller.twitter.followUsers = (twitterUsersIdsToFollow, firebaseUser, currentUserId) => {
  return firebaseUser.getIdToken().then((token) => {
    return Promise.all(twitterUsersIdsToFollow.map((twitterUserId) => {
      const url = `${httpRoutesURL}/twitter/followUser/${twitterUserId}`;
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const body = {
        currentUserId: currentUserId
      }

      return axios.post(url, body, { headers });
    }));
  });
};

controller.twitter.followHypefuryTwitterAccountAndUpdateRelatedTask = (
  firebaseUser,
  currentUserId
) => {
  return firebaseUser.getIdToken().then((token) => {
    const url = `${httpRoutesURL}/twitter/followHypefuryTwitterAccountAndUpdateRelatedTask`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    return axios.post(url, { currentUserId }, { headers });
  });
};

controller.twitter.setFollowHypefuryTaskAsCompleteIfHypefuryIsFollowedByUser = (
  firebaseUser,
  currentUserId
) => {
  return firebaseUser.getIdToken().then((token) => {
    const url = `${httpRoutesURL}/twitter/setFollowHypefuryTaskAsCompleteIfHypefuryIsFollowedByUser`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    return axios.post(url, { currentUserId }, { headers });
  });
};

controller.getAccountsFollowedByUserFromList = (twitterUsersIds, firebaseUser, currentUserId) => {
  return firebaseUser.getIdToken().then((token) => {
    const url = `${httpRoutesURL}/getUsersFollowStatus/${currentUserId}/${twitterUsersIds}`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    return axios
      .get(url, { headers })
      .then((response) =>
        response.data
          .filter((user) => user.connection_status.includes('following'))
          .map((followedUsers) => followedUsers.id)
      );
  });
};

controller.getTweet = (tweetId, firebaseUser, currentUserId) => {
  return firebaseUser.getIdToken().then((token) => {
    const url = `${httpRoutesURL}/getTweet/${currentUserId}/${tweetId}`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    return axios.get(url, { headers }).then((response) => response.data);
  });
};

controller.retweet = (firebaseUser, threadId, currentUserId) => {
  return firebaseUser.getIdToken().then(token => {
    const url = `${httpRoutesURL}/retweet`;
    const headers = {
      'Authorization': `Bearer ${token}`,
    };

    const body = {
      currentUserId,
      threadId
    }

    return axios.post(url, body, { headers });
  })
};

controller.getServicebotHMAC = firebaseUser => {
  return firebaseUser.getIdToken()
    .then(token => {
      const headers = { 'Authorization': `Bearer ${token}` };
      return axios.get(`${httpRoutesURL}/HMACGenerator/servicebot`, { headers }).then(r => r.data);
    });
};

controller.getChurnkeyHMAC = (firebaseUser, isStripeCustomer) => {
  return firebaseUser.getIdToken()
    .then(token => {
      const headers = { 'Authorization': `Bearer ${token}` };
      const url = isStripeCustomer ? 'HMACGenerator/churnkey' : 'ChurnkeyHMACGenerator';
      return axios.get(`${httpRoutesURL}/${url}`, { headers }).then(r => r.data);
    });
};

controller.getTwitterUserInfo = (firebaseUser, currentUser, twitterUserId) =>
  firebaseUser.getIdToken().then((token) => {
    const headers = { Authorization: `Bearer ${token}` };
    const url = `${httpRoutesURL}/getTwitterUserInfo/${twitterUserId}/${currentUser.uid}`
    return axios.get(url, { headers }).then((response) => response.data)
      .catch((error) => {
        throw error;
      });;
  });

controller.isThereAnotherUserWithTheSameEmailAddress = (firebaseUser, emailAddress, currentUser) => {
  return firebaseUser.getIdToken()
    .then(token => {
      const headers = { 'Authorization': `Bearer ${token}` };
      const config = { headers };
      const encodedEmailAddress = encodeURIComponent(emailAddress);
      const url = `${httpRoutesURL}/isThereAnotherUserWithTheSameEmailAddress/${currentUser}/${encodedEmailAddress}`;
      return axios.get(url, config)
        .then(r => r.status === 200)
        .catch(() => false);
    });
};

controller.extendTrial = (firebaseUser, userId, extensionId) => firebaseUser.getIdToken().then(token => {
  const headers = { 'Authorization': `Bearer ${token}` };
  const body = {
    currentUserId: userId,
    extensionId,
  };
  return axios.post(`${httpRoutesURL}/extendTrial`, body, { headers });
});

controller.resetTrial = firebaseUser => firebaseUser.getIdToken().then(token => {
  const headers = { 'Authorization': `Bearer ${token}` };
  const body = { currentUserId: firebaseUser.uid };
  return axios.post(`${httpRoutesURL}/resetTrial`, body, { headers });
});

controller.shuffleQueue = (firebaseUser, currentUser) =>
  firebaseUser.getIdToken().then((token) => {
    const currentUserId = currentUser.uid;
    const headers = { Authorization: `Bearer ${token}` };
    return axios.post(`${httpRoutesURL}/shuffleQueue`, { currentUserId }, { headers });
  });

controller.moveAllQueuedPostsToTop = (firebaseUser, currentUser) =>
  firebaseUser.getIdToken().then((token) => {
    const currentUserId = currentUser.uid;
    const headers = { Authorization: `Bearer ${token}` };
    return axios.post(`${httpRoutesURL}/moveAllQueuedPostsToTop`, { currentUserId }, { headers });
  });

controller.deleteAllQueuedPosts = (firebaseUser, currentUser) =>
  firebaseUser.getIdToken().then((token) => {
    const currentUserId = currentUser.uid;
    const headers = { Authorization: `Bearer ${token}` };
    return axios.post(`${httpRoutesURL}/deleteAllQueuedPosts`, { currentUserId }, { headers });
  });

controller.enableThreadsCrosspostingForAllQueuedPosts = (firebaseUser, currentUser) =>
  firebaseUser.getIdToken().then((token) => {
    const currentUserId = currentUser.uid;
    const headers = { Authorization: `Bearer ${token}` };
    return axios.post(`${httpRoutesURL}/enableThreadsCrosspostingForAllQueuedPosts`, { currentUserId }, { headers });
  });

controller.disableThreadsCrosspostingForAllQueuedPosts = (firebaseUser, currentUser) =>
  firebaseUser.getIdToken().then((token) => {
    const currentUserId = currentUser.uid;
    const headers = { Authorization: `Bearer ${token}` };
    return axios.post(`${httpRoutesURL}/disableThreadsCrosspostingForAllQueuedPosts`, { currentUserId }, { headers });
  });

controller.enableThreadsCrosspostingForAllCategoryPosts = (firebaseUser, currentUser, categoryId) =>
  firebaseUser.getIdToken().then((token) => {
    const currentUserId = currentUser.uid;
    const headers = { Authorization: `Bearer ${token}` };
    return axios.post(
      `${httpRoutesURL}/enableThreadsCrosspostingForAllCategoryPosts`,
      { currentUserId, categoryId },
      { headers },
    );
  });

controller.disableThreadsCrosspostingForAllCategoryPosts = (
  firebaseUser,
  currentUser,
  categoryId,
) =>
  firebaseUser.getIdToken().then((token) => {
    const currentUserId = currentUser.uid;
    const headers = { Authorization: `Bearer ${token}` };
    return axios.post(
      `${httpRoutesURL}/disableThreadsCrosspostingForAllCategoryPosts`,
      { currentUserId, categoryId },
      { headers },
    );
  });

controller.gumroadProducts = (firebaseUser, currentUserId) => firebaseUser.getIdToken().then(token => {
  const headers = {
    'Authorization': `Bearer ${token}`,
  };
  const url = `${httpRoutesURL}/gumroad/products/${currentUserId}`;
  return axios({ url, method: 'get', headers, }).then(response => response.data);
});

controller.gumroadGetOfferCode = (firebaseUser, currentUserId, productId, offerCodeName) =>
  firebaseUser.getIdToken().then((token) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const url = `${httpRoutesURL}/gumroad/products/${currentUserId}/${productId}/offerCodes`;
    return axios({ url, method: 'get', headers })
      .then((response) => response.data)
      .then((data) => {
        return lodash.head(data.filter((offerCode) => offerCode.name === offerCodeName.toLowerCase()));
      });
  });

controller.previewLink = (firebaseUser, url) => firebaseUser.getIdToken().then(token => {
  const headers = { 'Authorization': `Bearer ${token}` };
  return axios.get(`${httpRoutesURL}/previewLink?url=${encodeURIComponent(url)}`, { headers });
});

controller.twitter.usersSearch = (firebaseUser, keyword) => firebaseUser.getIdToken().then(token => {
  const headers = { 'Authorization': `Bearer ${token}` };
  return axios
    .get(`${httpRoutesURL}/twitter/users/search/${keyword}`, { headers })
    .then((response) => response.data);
});

controller.searchForKeyword = (firebaseUser, currentUser, isFavorite, keyword) =>
  firebaseUser.getIdToken().then((token) => {
    const headers = { Authorization: `Bearer ${token}` };
    const url = `${httpRoutesURL}/searchKeyword/${keyword}/user/${currentUser.uid}${
      lodash.isNil(isFavorite) ? '' : `?isFavorite=${isFavorite}`
    }`;
    return axios.get(url, { headers }).then((response) => response.data);
  });

controller.generateTweetshot = (firebaseUser, threadId) =>
  firebaseUser.getIdToken().then((token) => {
    const url = `${httpRoutesURL}/generateTweetshot/${firebaseUser.uid}/${threadId}`;
    const headers = { Authorization: `Bearer ${token}` };
    return axios.get(url, { headers });
  });

controller.generateTweetshotFromText = (
  firebaseUser,
  currentUserId,
  text,
  mediaURL,
  isLongTweetshot,
  isLargeFontTweetshot
) =>
  firebaseUser.getIdToken().then((token) => {
    const url = `${httpRoutesURL}/generateTweetshotFromText`;
    const body = { currentUserId, text, mediaURL, isLongTweetshot, isLargeFontTweetshot };
    const headers = { Authorization: `Bearer ${token}` };
    return axios.post(url, body, { headers }).then((response) => response.data);
  });

controller.proxyFile = (firebaseUser, url) =>
  firebaseUser.getIdToken().then((token) => {
    const headers = { Authorization: `Bearer ${token}` };
    return axios
      .get(`${httpRoutesURL}/proxyFile?url=${url}`, { responseType: 'arraybuffer', headers })
      .then((response) => response.data);
  });

controller.searchGifs = (firebaseUser, query) => firebaseUser.getIdToken().then(token => {
  const headers = { 'Authorization': `Bearer ${token}` };
  return axios
    .get(`${httpRoutesURL}/tenor/v1/search?q=${query}`, { headers })
    .then((response) => response.data);
});

controller.getTrendingGifs = (firebaseUser) => firebaseUser.getIdToken().then(token => {
  const headers = { 'Authorization': `Bearer ${token}` };
  return axios
    .get(`${httpRoutesURL}/tenor/v1/trending`, { headers })
    .then((response) => response.data);
});

controller.getGeneratedQuote = async (firebaseUser) => {
  const firebaseToken = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${firebaseToken}` };
  const categories = ['business', 'money'];
  const category = categories[Math.floor(Math.random() * categories.length)];
  const response = await axios.get(`${httpRoutesURL}/generateQuote/${category}`, { headers });
  return response.data;
};

controller.upgradeStripeUserToPremium = (firebaseUser) =>
  firebaseUser.getIdToken().then((token) => {
    const body = { currentUserId: firebaseUser.uid };
    const headers = { Authorization: `Bearer ${token}` };
    return axios
      .post(`${httpRoutesURL}/upgradeStripeUserToPremium`, body, { headers })
      .catch((error) => {
        throw error;
      });
  });

controller.upgradeStripeUserToYearly = (firebaseUser, couponCode = null) =>
  firebaseUser.getIdToken().then((token) => {
    const body = { currentUserId: firebaseUser.uid, couponCode };
    const headers = { Authorization: `Bearer ${token}` };
    return axios.post(`${httpRoutesURL}/upgradeStripeUserToYearly`, body, { headers }).catch((error) => {
      throw error;
    });
  });

controller.upgradeUserToPremium = (firebaseUser, newPriceInfo = null) => firebaseUser.getIdToken().then(token => {
  const body = { currentUserId: firebaseUser.uid, newPriceInfo };
  const headers = { 'Authorization': `Bearer ${token}` };
  return axios
    .post(`${httpRoutesURL}/upgradeUserToPremium`, body, { headers })
    .catch((error) => {
      throw error;
    });
});

controller.upgradeUserToYearly = (
  firebaseUser,
  newPriceInfo = null,
  couponCode = null,
  newPlan = null,
  keepModifiers = false
) =>
  firebaseUser.getIdToken().then((token) => {
    const body = {
      currentUserId: firebaseUser.uid,
      newPriceInfo,
      couponCode,
      newPlan,
      keepModifiers,
    };
    const headers = { Authorization: `Bearer ${token}` };
    return axios.post(`${httpRoutesURL}/upgradeUserToYearly`, body, { headers }).catch((error) => {
      throw error;
    });
  });

controller.getUserAffiliateDetails = async (firebaseUser, currentUser) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const response = await axios.get(`${httpRoutesURL}/getUserAffiliateDetails/${currentUser.uid}`, { headers });
  return response.data;
};

controller.createAffiliateAccount = async (firebaseUser, currentUser) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const body = { currentUserId: currentUser.uid };
  return axios.post(`${httpRoutesURL}/createAffiliateAccount`, body, { headers });
};

controller.dismissOnboardingChecklist = async (firebaseUser, currentUser) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const body = { currentUserId: currentUser.uid };
  return axios.post(`${httpRoutesURL}/dismissOnboardingChecklist`, body, { headers });
};

controller.setTaskAsCompleted = async (firebaseUser, currentUserId, taskName) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  return axios.post(`${httpRoutesURL}/setTaskAsCompleted`, { currentUserId, taskName } , { headers });
};

controller.setTaskAsCanceled = async (firebaseUser, currentUserId, taskName) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  return axios.post(`${httpRoutesURL}/setTaskAsCanceled`, { currentUserId, taskName } , { headers });
};

controller.setBestTweetsAsEvergreen = async (firebaseUser, currentUser, posts) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };

  const postsIds = posts.map(post => post.id);

  return axios.post(`${httpRoutesURL}/setBestTweetsAsEvergreen`, { currentUserId: currentUser.uid , postsIds } , { headers });
};

controller.getFollowedUsers = async (firebaseUser, currentUserId, page) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  return axios.get(`${httpRoutesURL}/getFollowedUsers/${currentUserId}/${page}`, { headers });
}

controller.setAllQueuedPostsAsEvergreen = async (firebaseUser, currentUser) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  return axios.post(
    `${httpRoutesURL}/setAllQueuedPostsAsEvergreen`,
    { currentUserId: currentUser.uid },
    { headers }
  );
};

controller.addAccountToManage = async (firebaseUser, currentUserId, managerInvitationToken) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  return axios.post(`${httpRoutesURL}/addAccountToManage`, {
    currentUserId,
    managerInvitationToken,
  },  { headers });
}

controller.removeManager = async (firebaseUser, managedAccountUserId, managerUserId) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  return axios.post(`${httpRoutesURL}/removeManager`, {
    managedAccountUserId,
    managerUserId,
  },  { headers });
}

controller.analytics.getTweetsAnalytics = (userId, days) => axios
    .get(`${httpRoutesURL}/metrics/getTweetsAnalytics/${userId}/${days}`)
    .then((response) => response.data);

controller.analytics.getAutoplugTweetsAnalytics = async (
  firebaseUser,
  userId,
  fromDate,
  toDate
) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  return axios
    .get(`${httpRoutesURL}/getAutoplugTweetsAnalytics/${userId}/${fromDate}/${toDate}`, {
      headers,
    })
    .then((response) => response.data);
};

controller.analytics.getTweets = (userId, days) => axios
    .get(`${httpRoutesURL}/metrics/getTweets/${userId}/${days}`)
    .then((response) => response.data);

controller.analytics.getFollowersAnalytics = (userId, days) => axios
    .get(`${httpRoutesURL}/metrics/getFollowersAnalytics/${userId}/${days}`)
    .then((response) => response.data);

controller.analytics.getUserAnalytics = (userId, date, isWeeksAnalytics) => axios
    .get(`${httpRoutesURL}/metrics/getUserAnalytics/${userId}/${date}/${isWeeksAnalytics}`)
    .then((response) => response.data);

controller.selectUsersToRetweet = (firebaseUser, currentUser, usernames) =>
  firebaseUser.getIdToken().then((token) => {
    const headers = { Authorization: `Bearer ${token}` };
    const body = {
      currentUserId: currentUser.uid,
      usernames,
    };
    return axios
      .post(`${httpRoutesURL}/setUsersToRetweet`, body, { headers })
      .then((response) => response.data);
  });

controller.getStripePlansPrices = async (firebaseUser) => {
  const firebaseToken = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${firebaseToken}` };
  const response = await axios.get(`${httpRoutesURL}/getStripePlansPrices`, { headers });
  return response.data;
};

controller.getLongLivedAccessToken = async (firebaseUser, accessToken) => {
  const firebaseToken = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${firebaseToken}` };
  const response = axios.post(`${httpRoutesURL}/integrations/auth/instagram/getLongLivedAccessToken`, { accessToken }, { headers });
  return response;
};

controller.connectThreads = async (firebaseUser, code) => {
  const firebaseToken = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${firebaseToken}` };
  const response = axios.post(`${httpRoutesURL}/integrations/auth/threads`, { code }, { headers });
  return response;
};

controller.getThreadsLongLivedAccessToken = async (firebaseUser, accessToken) => {
  const firebaseToken = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${firebaseToken}` };
  const response = axios.post(
    `${httpRoutesURL}/integrations/auth/threads/getLongLivedAccessToken`,
    { accessToken },
    { headers },
  );
  return response;
};

controller.getPagesOfFacebookAccount = async (firebaseUser, accessToken) => {
  const firebaseToken = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${firebaseToken}` };
  const response = await axios.post(`${httpRoutesURL}/integrations/auth/instagram/getPagesOfFacebookAccount`, { accessToken }, { headers });
  return response.data.data;
};

controller.getInstagramBusinessAccountOfPage = async (firebaseUser, accessToken, pageId) => {
  const firebaseToken = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${firebaseToken}` };
  const response = await axios.post(`${httpRoutesURL}/integrations/auth/instagram/getPageInstagramBusinessAccount`, { accessToken, pageId }, { headers });
  return response.data;
};

controller.getThreadsUserInfo = async (firebaseUser, accessToken, currentUserId) => {
  const firebaseToken = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${firebaseToken}` };
  const response = await axios.post(
    `${httpRoutesURL}/integrations/auth/threads/getUserInfo`,
    { accessToken, currentUserId },
    { headers },
  );
  return response;
};

controller.getInstagramUserInfo = async (firebaseUser, accessToken, currentUserId) => {
  const firebaseToken = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${firebaseToken}` };
  const response = await axios.post(`${httpRoutesURL}/integrations/auth/instagram/getUserInfo`, { accessToken, currentUserId }, { headers });
  return response.data;
};

controller.doesAccountExistInHypefury = async (user, accessTokenKey, accessTokenSecret) => {
  const firebaseToken = await user.getIdToken();
  const url = `${httpRoutesURL}/doesAccountExistInHypefury`;
  const body = { accessTokenKey, accessTokenSecret };
  const response = await axios.post(url, body, {
    /**
     * This "hack" had to be added because with multiaccount support and the usage of
     * vue-authenticate. For some reason, even though we've configured it not to delete
     * the Authorization header from Axios, it still deletes it.
     *
     * The only way I found around this was to modify the request just before it goes out and
     * add the missing Authorization header.
     *
     * TODO: Figure out why vue-authenticate deletes the Authorization header when used in SideBar.vue.
     */
    transformRequest: [
      (data, headers) => {
        headers['Authorization'] = `Bearer ${firebaseToken}`;
        return data;
      }, ...axios.defaults.transformRequest],
  });
  return response.data;
};

controller.removeAdditionalTwitterAccount = async (firebaseUser, currentUserId, accountIdToRemove) => {
  const firebaseToken = await firebaseUser.getIdToken();
  const url = `${httpRoutesURL}/removeAdditionalTwitterAccount`;
  const headers = { Authorization: `Bearer ${firebaseToken}` };
  const body = { accountIdToRemove, currentUserId };
  const response = await axios.post(url, body, { headers });
  return response.data;
};

controller.aiTweets.generateTweets = (firebaseUser, currentUserId, tweetsToFeed) =>
  firebaseUser.getIdToken().then((token) => {
    const headers = { Authorization: `Bearer ${token}` };

    if (tweetsToFeed) {
      return axios({
        url: `${httpRoutesURL}/ai/generateTweets`,
        method: 'POST',
        headers,
        data: { tweets: tweetsToFeed, currentUserId },
      });
    }

    return axios.get(`${httpRoutesURL}/ai/generateTweets`, { headers });
  });

controller.twitter.mentionsTimeline = async (firebaseUser, userId, untilId = null) =>{
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  let url = `${httpRoutesURL}/twitter/mentionsTimeline/${userId}`;

  if (untilId) {
    url = `${url}/?untilId=${untilId}`;
  }

  const response = await axios.get(url, { headers })
  return response.data;
}

controller.twitter.replyToTweet = async (firebaseUser, currentUserId, tweetId, replyText) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/twitter/replyToTweet`;
  const body = {
    currentUserId,
    tweetId,
    replyText,
  };
  const response = await axios.post(url, body, { headers });
  return response.data;
};

controller.twitter.likeTweet = async (firebaseUser, userId, tweetId) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/twitter/likeTweet/${userId}/${tweetId}`;
  const response = await axios.get(url, { headers });
  return response.data;
};

controller.twitter.searchTweets = async (firebaseUser, currentUserId) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/twitter/searchTweets/${currentUserId}`;
  const response = await axios.get(url, { headers });
  return response.data;
};

controller.twitter.getTwitterUsersByUsernames = async (firebaseUser, currentUserId, usernames) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/twitter/getTwitterUsersByUsernames/${currentUserId}`;
  const response = await axios.post(url, { usernames }, { headers });
  return response.data;
};

controller.twitter.fetchFeed = async (firebaseUser, currentUserId) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/twitter/fetchFeed/${currentUserId}`;
  const response = await axios.get(url, { headers });
  return response.data;
};

controller.twitter.retweet  = async (firebaseUser, currentUserId, tweetId) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/twitter/retweet/${currentUserId}/${tweetId}`;
  const response = await axios.get(url, { headers });
  return response.data;
};

controller.twitter.quoteTweet  = async (firebaseUser, currentUserId, quoteText, tweetId) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/twitter/quoteTweet`;
  const response = await axios.post(url, { currentUserId, quoteText, tweetId }, { headers });
  return response.data;
};

controller.twitter.getUsers = async (firebaseUser, currentUserId, usersIds) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/twitter/getUsers`;
  const response = await axios.post(url, { usersIds, currentUserId }, { headers });
  return response.data;
};

controller.createManagerInvitationToken  = async (firebaseUser, currentUserId) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/createManagerInvitationToken`;
  const response = await axios.post(url, { currentUserId }, { headers });
  return response.data;
};

controller.deleteManagerInvitationToken  = async (firebaseUser, currentUserId, managerInvitationToken) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/deleteManagerInvitationToken`;
  const response = await axios.post(url, { managerInvitationToken, currentUserId }, { headers });
  return response.data;
};

controller.pauseSubscription  = async (firebaseUser, durationInMonths) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/pauseSubscription/${durationInMonths}`;
  const response = await axios.get(url, { headers });
  return response.data;
};

controller.isUserFollowingHypefuryOnTwitter = async (firebaseUser, userId, userTwitterId, hypefuryTwitterId) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/getUsersFollowStatus/${userId}/${userTwitterId},${hypefuryTwitterId}`;
  const response = await axios.get(url, { headers });
  const hypefuryData = lodash
    .get(response, 'data', [])
    .find((user) => user.id === hypefuryTwitterId);
  const isUserFollowingHypefury = hypefuryData.connection_status.includes('following');
  return isUserFollowingHypefury;
}

controller.setScheduleFromFollowersActivity = async (
  firebaseUser,
  currentUserId,
  normalSlotsCounter,
  evergreenSlotsCounter
) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/setScheduleFromFollowersActivity`;
  const response = await axios.post(
    url,
    { currentUserId, normalSlotsCounter, evergreenSlotsCounter },
    { headers }
  );
  return response.data;
};

controller.addDeviceNotificationsToken = async (firebaseUser, currentUserId, deviceToken, updateOnboardingChecklist = false) => {
  const firebaseToken = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${firebaseToken}` };
  const response = axios.post(
    `${httpRoutesURL}/addDeviceNotificationsToken`,
    { currentUserId, deviceToken, updateOnboardingChecklist },
    { headers }
  );
  return response;
};

controller.deleteAccount = async (firebaseUser, currentUserId) => {
  const firebaseToken = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${firebaseToken}` };
  const response = await axios.post(`${httpRoutesURL}/deleteAccount`, { currentUserId }, { headers });
  return response.data;
};

controller.posts.save = async (firebaseUser, currentUserId, post, postOnNextEmptySlot) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/posts/save`;
  const response = await axios.post(url, { currentUserId, post, postOnNextEmptySlot }, { headers });
  return response.data;
};

controller.posts.update = async (firebaseUser, currentUserId, postId, post) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/posts/update`;
  const response = await axios.post(url, { currentUserId, postId, post }, { headers });
  return response.data;
};

controller.posts.updatePostTime = async (firebaseUser, currentUserId, postId, time) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/posts/updatePostTime`;
  const response = await axios.post(url, { currentUserId, postId, time }, { headers });
  return response.data;
};

controller.posts.postNow = async (firebaseUser, currentUserId, postId) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/posts/postNow`;
  const response = await axios.post(
    url,
    {
      currentUserId,
      postId,
    },
    { headers }
  );
  return response.data;
};

controller.posts.delete = async (firebaseUser, currentUserId, postId) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/posts/delete`;
  const response = await axios.post(
    url,
    {
      currentUserId,
      postId,
    },
    { headers }
  );
  return response.data;
};

controller.createHypefuryAffiliateCategoryAndScheduleIt = async (firebaseUser, currentUserId, referralLink) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/createHypefuryAffiliateCategoryAndScheduleIt`;
  const response = await axios.post(
    url,
    {
      referralLink,
      currentUserId,
    },
    { headers }
  );
  return response.data;
};

controller.users.create = async (firebaseUser, userData) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/users/create`;
  const response = await axios.post(url, { userData }, { headers });
  return response.data;
};

controller.users.updateGhostwritingClientsData = async (
  firebaseUser,
  currentUserId,
  clientId,
  content,
  fieldName
) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/users/updateGhostwritingClientsData`;
  const response = await axios.post(
    url,
    { currentUserId, clientId, content, fieldName },
    { headers }
  );
  return response.data;
};

controller.users.updateGhostwriterSettings = async (
  firebaseUser,
  currentUserId,
  fieldName,
  fieldValue
) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/users/updateGhostwriterSettings`;
  const response = await axios.post(url, { currentUserId, fieldName, fieldValue }, { headers });
  return response.data;
};

controller.generateAPIKey = async (firebaseUser, userId) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/externalApps/generateAPIKey/${userId}`;
  const response = await axios.get(url, { headers });
  return response.data;
};

controller.getInspirationTweets = async (firebaseUser, userId) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/getInspirationTweets/${userId}`;
  const response = await axios.get(url, { headers });
  return response.data;
};

controller.getBookmarkedCategories = async (firebaseUser, userId) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/getBookmarkedCategories/${userId}`;
  const response = await axios.get(url, { headers });
  return response.data;
};

controller.twitter.dmUsers = async (firebaseUser, currentUserId, usersIds, message) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/twitter/dmUsers`;
  const response = await axios.post(url, { currentUserId, usersIds, message }, { headers });
  return response.data;
};

controller.removeBookmarkedTweet = async (firebaseUser, currentUserId, categoryName, tweetId) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/removeBookmarkedTweet`;
  const response = await axios.post(url, { currentUserId, categoryName, tweetId }, { headers });
  return response.data;
};

controller.removeBookmarkCategory = async (firebaseUser, currentUserId, categoryName) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/removeBookmarkCategory`;
  const response = await axios.post(url, { currentUserId, categoryName }, { headers });
  return response.data;
};

controller.posts.sendToDrafts = async (firebaseUser, currentUserId, postId) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/posts/sendToDrafts`;
  const response = await axios.post(url, { currentUserId, postId }, { headers });
  return response.data;
};

controller.getUserSubscriptionDetails = async (firebaseUser, customerIp) => {
  const firebaseToken = await firebaseUser.getIdToken();
  const body = { customerIp };
  const url = `${httpRoutesURL}/getUserSubscriptionDetails`;
  const headers = { Authorization: `Bearer ${firebaseToken}` };
  const response = await axios.post(url, body, { headers });
  return response.data;
};

controller.getPlansPrices = async (firebaseUser, customerIp) => {
  const firebaseToken = await firebaseUser.getIdToken();
  const body = { customerIp };
  const headers = { Authorization: `Bearer ${firebaseToken}` };
  const url = `${httpRoutesURL}/getPlansPrices`;
  const response = await axios.post(url, body, { headers });
  return response.data;
};

controller.updateUserSubscription = async (firebaseUser, planId, newPriceInfo = null, keepModifiers = true) => {
  const body = { currentUserId: firebaseUser.uid, planId, newPriceInfo, keepModifiers };
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/updateUserSubscription`;
  const response = await axios.post(url, body, { headers });
  return response.data;
};

controller.cancelUserSubscription = async (firebaseUser) => {
  const body = { currentUserId: firebaseUser.uid };
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/cancelUserSubscription`;
  const response = await axios.post(url, body, { headers });
  return response.data;
};

controller.applyCancellationDiscount = async (firebaseUser, discountData) => {
  const body = { currentUserId: firebaseUser.uid, discountData };
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/applyCancellationDiscount`;
  const response = await axios.post(url, body, { headers });
  return response.data;
};

controller.applyStripeCancellationDiscount = async (firebaseUser) => {
  const body = { currentUserId: firebaseUser.uid };
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/applyStripeCancellationDiscount`;
  const response = await axios.post(url, body, { headers });
  return response.data;
};

controller.createCoupon = async (firebaseUser, couponData) => {
  const body = { couponData };
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/createCoupon`;
  const response = await axios.post(url, body, { headers });
  return response.data;
};

controller.updateCoupon = async (firebaseUser, couponData) => {
  const body = { couponData };
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/updateCoupon`;
  const response = await axios.post(url, body, { headers });
  return response.data;
};

controller.deleteCoupon = async (firebaseUser, couponCode, productIds) => {
  const body = { couponCode, productIds };
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/deleteCoupon`;
  const response = await axios.post(url, body, { headers });
  return response.data;
};

controller.fetchCoupons = async (firebaseUser, productId) => {
  const body = { productId };
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/fetchCoupons`;
  const response = await axios.post(url, body, { headers });
  return response.data;
};

controller.getCustomerCountry = async (firebaseUser, ipAddress) => {
  const body = { ipAddress };
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/getCustomerCountry`;
  const response = await axios.post(url, body, { headers });
  return response.data;
};

controller.aiTweets.increaseTotalScheduledAIPosts = async (firebaseUser, currentUserId, numberOfScheduledAIPosts) => {
  const body = { currentUserId, numberOfScheduledAIPosts };
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/increaseTotalScheduledAIPosts`;
  const response = await axios.post(url, body, { headers });
  return response.data;
};

controller.generateParityPayLink = async (firebaseUser, paylinkSettings) => {
  const body = { currentUserId: firebaseUser.uid, paylinkSettings };
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/generateParityPayLink`;
  const response = await axios.post(url, body, { headers });
  return response.data;
};

controller.isSourceValid = async (firebaseUser, source) => {
  const token = await firebaseUser.getIdToken();
  const url = `${httpRoutesURL}/isSourceValid/${source}`;
  const headers = { Authorization: `Bearer ${token}` };
  return axios.get(url, { headers });
};

controller.autoDM.getMetrics = async (firebaseUser, date) => {
  const token = await firebaseUser.getIdToken();
  const url = `${httpRoutesURL}/auto-dm/metrics/${date}`;
  const headers = { Authorization: `Bearer ${token}` };
  return axios.get(url, { headers });
};

controller.checkIfTextHasOptIn = async (firebaseUser, currentUserId, text) => {
  const token = await firebaseUser.getIdToken();
  const url = `${httpRoutesURL}/checkIfTextHasOptIn`;
  const headers = { Authorization: `Bearer ${token}` };
  return axios.post(url, { text, currentUserId }, { headers });
};

controller.upgradeUserToSelectedPlan = async (firebaseUser, newPriceInfo = null, selectedPlan) => {
  const token = await firebaseUser.getIdToken();
  const body = { currentUserId: firebaseUser.uid, newPriceInfo, selectedPlan };
  const headers = { Authorization: `Bearer ${token}` };
  return axios.post(`${httpRoutesURL}/upgradeUserToSelectedPlan`, body, { headers });
};

controller.generateAMATweet = async (firebaseUser, currentUserId) => {
  const token = await firebaseUser.getIdToken();
  const url = `${httpRoutesURL}/generateAMATweet/${currentUserId}`;
  const headers = { Authorization: `Bearer ${token}` };
  return axios.get(url, { headers });
};

controller.renderThreads = (firebaseUser, renderTasks) => {
  return firebaseUser.getIdToken().then((token) => {
    const url = `${httpRoutesURL}/renderTweetReel`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const body = {
      renderTasks,
      userId: firebaseUser.uid,
    };
    return axios.post(url, body, { headers });
  });
};

controller.replyToAMAReply = async (firebaseUser, currentUserId, data) => {
  const token = await firebaseUser.getIdToken();
  const url = `${httpRoutesURL}/replyToAMATweet`;
  const headers = { Authorization: `Bearer ${token}` };
  const body = {
    ...data,
    currentUserId,
  };
  return axios.post(url, body, { headers });
};

controller.getCurrentWeekTimeBasedSalesCount = async (firebaseUser, currentUserId) => {
  const token = await firebaseUser.getIdToken();
  const url = `${httpRoutesURL}/getCurrentWeekTimeBasedSalesCount/${currentUserId}`;
  const headers = { Authorization: `Bearer ${token}` };
  return axios.get(url, { headers });
};

controller.getCurrentWeekGumroadSalesCount = async (firebaseUser, currentUserId) => {
  const token = await firebaseUser.getIdToken();
  const url = `${httpRoutesURL}/getCurrentWeekGumroadSalesCount/${currentUserId}`;
  const headers = { Authorization: `Bearer ${token}` };
  return axios.get(url, { headers });
};

controller.getUserYouTubeChannels = async (firebaseUser, userProfile) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/youtube/getUserChannels`;
  const response = await axios.post(url, { currentUserId: userProfile.uid }, { headers });
  return response.data;
};

controller.youtube.getUserVideos = async (firebaseUser, userProfile) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/youtube/getUserVideos`;
  const response = await axios.post(url, { currentUserId: userProfile.uid }, { headers });
  return response.data;
};

controller.youtube.getVideosDetails = async (firebaseUser, userProfile, videoIds) => {
  const token = await firebaseUser.getIdToken();
  const headers = { Authorization: `Bearer ${token}` };
  const url = `${httpRoutesURL}/youtube/getVideosDetails`;
  const response = await axios.post(url, { currentUserId: userProfile.uid, videoIds }, { headers });
  return response.data;
};

export default controller;
